import { createSelector } from '@reduxjs/toolkit'
import { isPast } from 'date-fns'
import type { ReduxState } from 'store/types'

const getFreeSpins = (state: ReduxState) => state.freeSpins
export const getNotCountingSuppliers = (state: ReduxState) => state.freeSpins.notCountingSuppliers

export const getFreeSpinsSelector = createSelector(getFreeSpins, ({ freeSpins }) =>
  freeSpins.filter((freeSpin) => !isPast(new Date(freeSpin.expireAt)) && freeSpin.rounds > freeSpin.roundsPlayed)
)

export const getNonWSFreeSpinsSelector = createSelector(getFreeSpinsSelector, (freeSpins) =>
  freeSpins.filter(
    // field .fromWS will be added to packages after successful testing
    // @ts-ignore
    (freeSpin) => !freeSpin.fromWS
  )
)

export const getFreeSpinsLoadingSelector = (state: ReduxState) => state.freeSpins.loading
